import { useEffect, useRef } from "react";
import { t } from "i18next";

const RegistrationSuccess: React.FC = () => {
  const targetDivRef = useRef<HTMLDivElement | null>(null);

  //I did not know where to put this TODO :D
  //So, remove margins from the "inside" main components and implement spacing in the parent element
  //I have already did for main - added gap-4 and then all the elements inside have the same gap
  //Sshould be implemeneted similarily for the other elements...

  //Why the scroll does not work?
  useEffect(() => {
    if (targetDivRef.current) {
      console.log(
        "scrolling!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
      );
      targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <div
        id="regSuccessful"
        className="flex flex-col items-center justify-center p-8 bg-accent rounded-lg h-full"
      >
        <p className="text-lg font-bold text-center">
          {t("fenix_registration_success")}
        </p>
        <p className="text-center">
          {t("fenix_registration_success_infomail")}
        </p>

        <p className="font-semibold text-center">
          {t("fenix_registration_success_bye")}
        </p>
        <div className="flex flex-row gap-4">
          <label className="swap swap-flip text-3xl animate-ping mb-5">
            <input type="checkbox" />
            <div className="swap-off">📧</div>
            <div className="swap-on">🔥</div>
          </label>
          <label className="swap swap-flip text-5xl p-5 animate-bounce">
            <input type="checkbox" />
            <div className="swap-off">🥳</div>
            <div className="swap-on">🏃</div>
          </label>
        </div>
      </div>
    </>
  );
};

export default RegistrationSuccess;
