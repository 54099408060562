interface SelectProps {
  labelTopRight?: string;
  labelBottomRight?: string;
  labelTopLeft?: string;
  labelBottomLeft?: string;
  colSpan?: number;
  rowSpan?: number;
  placeholder?: string;
  options: Record<number, string>[];
  required?: boolean;
  name: string;
}

const Select: React.FC<SelectProps> = ({
  labelTopRight = "",
  labelBottomRight = "",
  labelTopLeft = "",
  labelBottomLeft = "",
  placeholder = "",
  colSpan = 1,
  rowSpan = 1,
  options,
  required = false,
  name,
}) => {
  const elemOptions = options.flatMap((option) => Object.entries(option));

  return (
    <label className={`form-control w-full ${colSpan} ${rowSpan}`}>
      <div className="label">
        <span className="label-text">{labelTopLeft}</span>
        <span className="label-text-alt">{labelTopRight}</span>
      </div>
      <select
        name={name}
        required={required}
        className="select select-bordered select-primary"
      >
        <option value="" disabled selected hidden>
          {placeholder}
        </option>
        {elemOptions.map(([key, value]) => (
          <option value={key} key={key}>
            {value}
          </option>
        ))}
      </select>
      <div className="label h-8">
        <span className="label-text-alt">{labelBottomLeft}</span>
        <span className="label-text-alt">{labelBottomRight}</span>
      </div>
    </label>
  );
};

export default Select;
