// src/api/axiosInstance.ts
import axios from "axios";

const axiosInstance = axios.create({
    //baseURL: "http://localhost:8080",
    baseURL: "https://api.registration.fenixplzen.cz",
    //baseURL: "https://registration-service-test-28a393e06c80.herokuapp.com/",
    //Will be handled by vite proxy to satisfy CORS
    //baseURL: "/", // Replace with your API base URL
    timeout: 10000,
});

// Add a request interceptor to include the auth token
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("authToken"); // Or any other storage where you keep the token
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Optionally, add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
