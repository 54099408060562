import { useQuery } from "@tanstack/react-query";
import defaultImg from "../assets/OCR_Race_Image_1.png";
import { getRace } from "../api/raceService";
import { ApiResponse, RaceDto } from "../interface/interface";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { getRaceKey } from "../constants/queryKeys";
import { useTranslation } from "react-i18next";
import Loading from "./daisyui/Loading";
import useImagePreloader from "../hooks/useImagePreloader";
import moment from "moment-timezone";

const Race: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const location = useLocation();
  const { t } = useTranslation();
  const images = useImagePreloader();

  const {
    data: apiResponse,
    error,
    isLoading,
    isSuccess,
  } = useQuery<ApiResponse<RaceDto>, Error>({
    queryKey: [getRaceKey, name],
    queryFn: () => {
      if (name) {
        const race = getRace(name);
        return race;
      }
      // Return a rejected promise if name is undefined to handle it in the query
      return Promise.reject(new Error("Race name is undefined"));
    },
    enabled: !!name,
  });

  if (isLoading) {
    return (
      //Todo move it to separate component
      <Loading />
    );
  }

  if (isSuccess) {
    //console.log(JSON.stringify(apiResponse.data));
  }

  if (error) {
    throw error;
    //return <ErrorModal message={error.message} />;
  }

  const regStart = moment(
    apiResponse && apiResponse.data
      ? apiResponse.data[0].registrationStart
      : new Date()
  );
  const regEnd = moment(
    apiResponse && apiResponse.data
      ? apiResponse.data[0].registrationEnd
      : new Date()
  );
  const timezone =
    apiResponse && apiResponse.data && apiResponse.data[0].timezone;

  const nowCz = moment().tz(timezone || "Europe/Prague");
  const regOpen =
    (nowCz.isSame(regStart) || nowCz.isAfter(regStart)) &&
    (nowCz.isBefore(regEnd) || nowCz.isSame(regEnd));

  return (
    <div className="grid grid-cols-1 2xl:grid-cols-2 gap-4">
      <div className="col-span-full lg:col-span-1 flex">
        <img
          // src={
          //   apiResponse && apiResponse.data && apiResponse.data[0].image
          //     ? apiResponse.data[0].image
          //     : race1
          // }
          src={
            apiResponse &&
            apiResponse.data &&
            apiResponse?.data[0].image &&
            images[`${apiResponse?.data[0].image}`]
              ? images[`${apiResponse?.data[0].image}`]
              : defaultImg
          }
          className="flex-grow rounded-lg shadow-2xl"
        />
      </div>
      <div className="flex flex-col col-span-full lg:col-span-1 justify-center">
        <h1 className="text-5xl font-bold text-center mb-3">
          {apiResponse && apiResponse.data && apiResponse.data[0].name}
        </h1>
        <div className="flex flex-col sm:flex-row gap-1 justify-center items-center">
          <p className="font-semibold">{t("race_place")}</p>
          <p>
            {/* TODO all those checks are pretty long, generalize for the response */}

            {apiResponse &&
              apiResponse.data &&
              apiResponse?.data[0].locationLink && (
                <a
                  href={apiResponse?.data[0].locationLink}
                  target={`${
                    apiResponse?.data[0].locationLink === "#" ? "" : "_blank"
                  }`}
                  className="ext-blue-500 underline hover:text-blue-700 hover:no-underline"
                >
                  {apiResponse &&
                    apiResponse.data &&
                    apiResponse.data[0].location}
                </a>
              )}
          </p>
        </div>
        <div className="flex flex-col sm:flex-row gap-1 justify-center items-center">
          <p className="font-semibold">{t("race_date")}</p>
          <p>
            {apiResponse &&
              apiResponse.data &&
              apiResponse.data[0].eventDate !== undefined &&
              new Date(apiResponse.data[0].eventDate).toLocaleDateString()}
          </p>
        </div>
        <div className="flex flex-col sm:flex-row gap-1 justify-center items-center">
          <p className="font-semibold">{t("race_details")}</p>
          <p>
            {apiResponse &&
              apiResponse.data &&
              apiResponse?.data[0].detailsLink && (
                <a
                  href={apiResponse?.data[0].detailsLink}
                  target="_blank"
                  className="ext-blue-500 underline hover:text-blue-700 hover:no-underline"
                >
                  {apiResponse &&
                    apiResponse.data &&
                    apiResponse?.data[0].details}
                </a>
              )}
          </p>
        </div>
      </div>

      <div className="flex flex-row gap-4 col-span-full">
        {/* {data?.raceFormats &&
          data.raceFormats.map((format: IRaceFormat) => (
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "btn btn-secondary flex-grow"
                  : "btn btn-primary flex-grow"
              }
              to={`${t("navigation_registration")}/${data?.raceId}/${t(
                `navigation_race_format_${format.name}`
              )}/new`}
            >
              {t(`navigation_race_format_${format.name}`)}
            </NavLink>
          ))} */}
        {apiResponse && apiResponse.data && apiResponse.data.length == 1 && (
          <NavLink
            className={({ isActive }) =>
              isActive && location.pathname.endsWith("new")
                ? "btn btn-secondary flex-grow"
                : "btn btn-primary flex-grow"
            }
            to={
              regOpen
                ? `registration/${apiResponse.data[0].raceId}/${apiResponse.data[0].raceFormat}/new`
                : "#"
            }
          >
            {regOpen ? t("new_registration") : t("new_registration_closed")}
          </NavLink>
        )}
      </div>
    </div>

    // <div className="hero bg-base-200 items-start rounded-lg">
    //   <div className="hero-content flex-col xl:flex-row-reverse">
    //     <img src={race1} className="rounded-lg shadow-2xl" />
    //     <div>
    //       <h1 className="text-5xl font-bold">Box Office News!</h1>
    //       <p className="py-6">Race name: {data?.name}</p>
    //       <p>
    //         Fusce dui leo, imperdiet in, aliquam sit amet, feugiat eu, orci.
    //         Aenean fermentum risus id tortor. Cum sociis natoque penatibus et
    //         magnis dis parturient montes, nascetur ridiculus mus. Integer
    //         malesuada. Aliquam erat volutpat. Donec vitae arcu. Proin mattis
    //         lacinia justo. Nulla non arcu lacinia neque faucibus fringilla.
    //         Nullam lectus justo, vulputate eget mollis sed, tempor sed magna.
    //         Etiam ligula pede, sagittis quis, interdum ultricies, scelerisque
    //         eu. Mauris tincidunt sem sed arcu. Sed vel lectus. Donec odio tempus
    //         molestie, porttitor ut, iaculis quis, sem. Ut enim ad minima veniam,
    //         quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi
    //         ut aliquid ex ea commodi consequatur? Cum sociis natoque penatibus
    //         et magnis dis parturient montes, nascetur ridiculus mus. Maecenas
    //         libero. Aliquam erat volutpat. Aliquam ornare wisi eu metus. Aenean
    //         vel massa quis mauris vehicula lacinia. Mauris tincidunt sem sed
    //         arcu. Nullam dapibus fermentum ipsum.
    //       </p>
    //       <div className="flex md:flex-row flex-col gap-4">
    //         <button className="btn active:bg-black btn-primary sm:min-w-40">
    //           Jednotlivec
    //         </button>
    //         <button className="btn btn-primary sm:min-w-40">Dvojice</button>
    //         <button className="btn btn-primary sm:min-w-40">Team</button>
    //         <button className="btn btn-primary sm:min-w-40">Štafeta</button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Race;
