import { Outlet } from "react-router-dom";

const RacesRootLayout: React.FC = () => (
  //nothing else here for now
  <>
    <Outlet />
  </>
);

export default RacesRootLayout;
