import { useRouteError } from "react-router-dom";
import NavBar from "../components/daisyui/NavBar";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

const ErrorPage: React.FC = () => {
  const error = useRouteError();
  const { t } = useTranslation();

  let message: string;

  if (error instanceof Error) {
    // Now you can safely access error.message
    message = error.message;
  } else if (
    typeof error === "object" &&
    error !== null &&
    "status" in error &&
    "statusText" in error
  ) {
    // You can access error.status and error.statusText
    message = error.status + " " + error.statusText;
  } else {
    // Fallback for unknown error types
    message = "An unexpected error has occurred.";
  }

  return (
    <div className="flex flex-col min-h-screen p-5">
      <NavBar />
      <main className="flex flex-col flex-grow bg-base-200 rounded-lg p-5 gap-4">
        <section>
          <div
            id="regSuccessful"
            className="flex flex-col items-center justify-center p-8 bg-error rounded-lg h-full gap-3"
          >
            <p className="font-semibold">{t("message_error_occurred")}</p>
            <p>{t("message_error_page_refresh")}</p>
            <p className="font-semibold">{t("message_error_not_resolved")}</p>
            <p>{message}</p>
            <p className="flex flex-row gap-2">
              <a
                href="https://www.instagram.com/fenix_plzen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a
                href="https://www.facebook.com/Fenix.Plzen"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ErrorPage;
