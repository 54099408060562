import axiosInstance from "./axiosInstance";

export const getRaces = async () => {
    try {
        const response = await axiosInstance.get(`/api/races`);
        return response.data;
    } catch (error) {
        console.error("Error fetching races:", error);
        throw error;
    }
};

export const getRace = async (name: string) => {
    try {
        const response = await axiosInstance.get(
            `/api/races/${encodeURIComponent(name)}`
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching race:", error);
        throw error;
    }
};

export const getRaceCategories = async (raceId: string) => {
    try {
        const response = await axiosInstance.get(`/api/races/${raceId}/categories`);
        return response.data;
    } catch (error) {
        console.error("Error fetching race:", error);
        throw error;
    }
};

export const getCurrentRacePrices = async (raceId: string) => {
    try {
        const response = await axiosInstance.get(`/api/races/${raceId}/prices`);
        return response.data;
    } catch (error) {
        console.error("Error fetching price:", error);
        throw error;
    }
};
