// formUtils.ts

import { AxiosError } from "axios";
import { ApiResponse, RegistrationDto } from "../interface/interface";

// Define the type for the saveRegistration function
type SaveRegistrationFunction = (
  data: RegistrationDto
) => Promise<ApiResponse<null>>;

export const handleRegistrationSubmission = async (
  data: RegistrationDto,
  saveRegistration: SaveRegistrationFunction
): Promise<ApiResponse<null>> => {
  try {
    const responseData = await saveRegistration(data);

    return responseData;

    // if (responseData.success) {
    //   return { success: true, message: responseData.message };
    // } else {
    //   // returned error code in response data from spring api
    //   return {
    //     success: false,
    //     errors: [{ errorMessage: responseData.message }],
    //   };
    // }
  } catch (error: unknown) {
    console.error("Error submitting form:", error);

    if (error instanceof AxiosError) {
      // Validation errors occurred
      // Validation errors arrived
      if (error.response) {
        const response = error.response.data as ApiResponse<null>;
        console.log("Error response: " + JSON.stringify(response));
        return response;
      } else {
        return { success: false, errors: [{ errorMessage: error.message }] };
      }
      // const validationErrors = error.response.data as SpringValidationError[];
      // const formattedErrors: ApiError[] = validationErrors.map(
      //   (err: SpringValidationError) => ({
      //     errorTarget: err.field,
      //     errorMessage: err.defaultMessage,
      //   })
      // );
      // return { success: false, errors: formattedErrors };
      //} else if (error instanceof Error) {
      // Check if it's a generic JavaScript Error
      //  return { success: false, errors: [{ errorMessage: error.message }] };
    } else {
      // Fallback for unexpected types of errors
      return {
        success: false,
        errors: [{ errorMessage: "An unknown error occurred" }],
      };
    }
  }
};
