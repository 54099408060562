import { Outlet } from "react-router-dom";
import NavBar from "../components/daisyui/NavBar";
import Footer from "../components/Footer";
// import { useTransition, presets } from "react-transition-state";
// import { useEffect } from "react";
// import { faLessThan } from "@fortawesome/free-solid-svg-icons/faLessThan";

const RootLayout: React.FC = () => {
  // const location = useLocation(); // Get current route location

  // const [{ status, isMounted }, toggle] = useTransition({
  //   timeout: 500,
  //   mountOnEnter: true,
  //   unmountOnExit: true,
  //   preEnter: false,
  // });

  // useEffect(() => {
  //   console.log("Toggling, location pathname: " + location.pathname);
  //   toggle(); // Trigger transition on route change
  // }, [location.pathname]);

  return (
    <div className="flex flex-col min-h-screen p-5">
      <NavBar />
      {/* <div className={`example ${status}`}> */}
      <main className="flex flex-col flex-grow bg-base-200 rounded-lg p-5 gap-4">
        <Outlet />
      </main>
      {/* </div> */}
      <Footer />
    </div>
  );
};

export default RootLayout;
