interface CheckboxProps {
  text?: string;
  title?: string;
  name: string;
  required: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  text = "",
  title = "",
  name,
  required = false,
}) => {
  return (
    <div className="form-control">
      <span className="font-bold">{title}</span>
      <label className="label cursor-pointer flex-row gap-1">
        <span className="label-text text-left">{text}</span>
        <input
          required={required}
          name={name}
          type="checkbox"
          defaultChecked={false}
          className="checkbox checkbox-warning border-2"
        />
      </label>
    </div>
  );
};

export default Checkbox;
