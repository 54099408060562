const Loading: React.FC = () => {
  return (
    // <div className="absolute inset-0 flex items-center justify-center">
    <div className="grid grid-rows-1 grid-cols-1 w-full h-full place-items-center">
      <span className="loading loading-bars text-accent w-[10%]"></span>
    </div>
    // </div>
  );
};

export default Loading;
