import {useTranslation} from "react-i18next";
import {PriceDto} from "../../interface/interface";

interface CurrencySelectorProps {
    prices?: PriceDto[];
}

const CurrencySelector: React.FC<CurrencySelectorProps> = ({prices}) => {
    const {i18n} = useTranslation();
    console.log("language: " + i18n.language);

    const checkedCurrency = i18n.language === "cs" ? "CZK" : "EUR";

    return (
        <div className="">
            {prices?.map(price => {
                return (
                    <div className="form-control" key={price.priceId}>
                        <label className="label cursor-pointer">
                            <span className="label-text">Platba {price?.value} {price.currency}</span>
                            <input
                                type="radio"
                                name="priceRadio"
                                value={price.priceId}
                                className="radio radio-warning border-2"
                                defaultChecked={price.currency === checkedCurrency}
                            />
                        </label>
                    </div>)
            })}
        </div>
    );
};

export default CurrencySelector;
