import "./fontawesome";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import "./App.css";

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import RootLayout from "./pages/RootLayout";
import Error from "./pages/ErrorPage";
import RacesRootLayout from "./pages/RacesRootLayout";
import RacePage from "./pages/RacePage";
import RacesPage from "./pages/RacesPage";
import { registrationFormFenixAction } from "./components/forms/FormFenix";
import NewRegistrationPage from "./pages/NewRegistrationPage";
import NewRegistrationSuccessPage from "./pages/NewRegistrationSuccessPage";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    element: <RootLayout />,
    errorElement: <Error />,
    children: [
      {
        index: true, // This is the default route when "/"
        element: <Navigate to="/race" replace />, // Redirect to "/zavody"
      },
      {
        path: "race",
        element: <RacesRootLayout />,
        children: [
          {
            index: true,
            element: (
              //TODO experiment with Transition component...
              //<Transition>
              <RacesPage />
              //</Transition>
            ),
          },
          {
            id: "raceDetails",
            path: ":name",
            element: <RacePage />,
            children: [
              {
                //maybe remove translation from the urls, as it looks like react router does not register
                //an url once I use translation here, for "new" for example...
                path: `registration/:raceId/:raceFormat/new`,
                element: <NewRegistrationPage />,
                action: registrationFormFenixAction,
              },
              {
                id: "registrationSuccess",
                // path: t("navigation_registration_edit"),
                path: "registration/:raceId/:raceFormat/new/success",
                element: <NewRegistrationSuccessPage />,
              },
              {
                id: "registrationEdit",
                // path: t("navigation_registration_edit"),
                path: "registration/:raceId/:raceFormat/edit",
                element: <div>Registration edit content</div>,
              },
            ],
          },
        ],
      },
    ],
  },
]);

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
