import { Outlet } from "react-router-dom";
import Registration from "../components/Registration";

const NewRegistrationPage: React.FC = () => {
  return (
    <>
      <Registration />
      <Outlet></Outlet>
    </>
  );
};

export default NewRegistrationPage;
