interface TextAreaProps {
  labelTopRight?: string;
  labelBottomRight?: string;
  labelTopLeft?: string;
  labelBottomLeft?: string;
  cssClasses?: string;
  placeholder?: string;
  required?: boolean;
  initHeightCss?: string;
  name: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  labelTopLeft = "",
  labelTopRight = "",
  labelBottomLeft = "",
  labelBottomRight = "",
  placeholder = "",
  cssClasses = "",
  required = false,
  initHeightCss = "h-5",
  name,
}) => {
  return (
    <label className={`form-control ${cssClasses}`}>
      <div className="label">
        <span className="label-text">{labelTopLeft}</span>
        <span className="label-text-alt">{labelTopRight}</span>
      </div>
      <textarea
        name={name}
        className={`textarea textarea-bordered textarea-primary ${initHeightCss}`}
        placeholder={placeholder}
        required={required}
      ></textarea>
      <div className="label h-8">
        <span className="label-text-alt">{labelBottomLeft}</span>
        <span className="label-text-alt">{labelBottomRight}</span>
      </div>
    </label>
  );
};

export default TextArea;
