import Checkbox from "../daisyui/Checkbox";
import Input from "../daisyui/Input";
import Select from "../daisyui/Select";
import TextArea from "../daisyui/TextArea";
import i18next from "i18next";
import {
    ActionFunction,
    Form,
    useActionData,
    useLocation,
    useNavigate,
    useNavigation,
    useParams,
} from "react-router-dom";
import {ApiError, ApiResponse, ICategoryDto, PriceDto, RegistrationDto,} from "../../interface/interface";
import {saveRegistration} from "../../api/registrationService";
import ErrorMessage from "../daisyui/ErrorMessage";
import {handleRegistrationSubmission} from "../../util/formUtils";
import {useQuery} from "@tanstack/react-query";
import {getCategoriesKey, getCurrentPriceKey,} from "../../constants/queryKeys";
import {getCurrentRacePrices, getRaceCategories} from "../../api/raceService";
import Loading from "../daisyui/Loading";
import CurrencySelector from "../daisyui/CurrencySelector";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

//i18n here:
//https://github.com/i18next/react-i189next

const FormElite: React.FC = () => {
    //not possible to use type here so far, useActionData returns uknown
    const apiResponseData = useActionData() as ApiResponse<RegistrationDto>;
    const {raceId, raceFormat} = useParams<{
        raceId: string;
        raceFormat: string;
    }>();
    const navigation = useNavigation();
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();

    //navigate only after the render cycle is finished - useEffect
    useEffect(() => {
        if (apiResponseData && apiResponseData.success) {
            navigate(
                location.pathname +
                "/" +
                "success" +
                "?id=" +
                (apiResponseData.data
                    ? apiResponseData.data[0].registrationId
                    : undefined)
            );
        }
    }, [apiResponseData, navigate, location.pathname]);

    //TODO in the future create one query to cover all the registration form requirements
    const categoryQuery = useQuery<ICategoryDto[], Error>({
        queryKey: [getCategoriesKey, raceId],
        queryFn: () => {
            if (raceId) {
                const categories = getRaceCategories(raceId);
                return categories;
            }
            // Return a rejected promise if name is undefined to handle it in the query
            return Promise.reject(new Error("Race id is undefined"));
        },
        enabled: !!raceId,
    });

    //TODO in the future create one query to cover all the registration form requirements
    const priceQuery = useQuery<ApiResponse<PriceDto>, Error>({
        queryKey: [getCurrentPriceKey, raceId],
        queryFn: () => {
            if (raceId) {
                const prices = getCurrentRacePrices(raceId);
                return prices;
            }
            // Return a rejected promise if name is undefined to handle it in the query
            return Promise.reject(new Error("Race id is undefined"));
        },
        enabled: !!raceId,
    });

    // Here is how to change language
    // const changeLanguage = (lng: string) => {
    //   i18n.changeLanguage(lng);
    // };

    // changeLanguage("cs");

    // if (navigation.state === "submitting") {
    //   return <Loading />;
    // }

    const sexOptions: Record<number, string>[] = [
        {1: t("form_sex_option_male"), 0: t("form_sex_option_female")},
    ];

    const nationalityOptions: Record<string, string>[] = [
        {
            CZE: "CZE",
            AUT: "AUT",
            ESP: "ESP",
            GER: "GER",
            HUN: "HUN",
            ITA: "ITA",
            LTU: "LTU",
            NED: "NED",
            POL: "POL",
            SVK: "SVK",
            Other: t("form_nationality_option_other"),
        },
    ];

    let categoryOptions: Record<number, string>[] = [];

    if (categoryQuery.isSuccess) {
        categoryOptions = categoryQuery.data.map((category) => ({
            [category.categoryId]: t(`form_category_option_${category.name}`),
        }));
    }

    let prices: PriceDto[] | undefined;

    if (priceQuery.isSuccess) {
        prices = priceQuery.data.data ? priceQuery.data.data : undefined;
    }

    //TODO make just one query or reuse getRace, anyway it is cached
    if (categoryQuery.error) {
        throw categoryQuery.error;
        //return <ErrorModal message={categoryQuery.error.message} />;
    }

    if (priceQuery.error) {
        throw categoryQuery.error;
        // return <ErrorModal message={priceQuery.error.message} />;
    }

    const gridClasses =
        "grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4";
    const requiredLabel = t("form_required_field");
    const selectOneOptionLabel = t("form_category_select_placeholder_option");
    return (
        <Form method="post" className="">
            <input name="raceId" type="hidden" value={raceId}/>
            {/* TODO race format data  */}
            <input name="raceFormat" type="hidden" value={raceFormat}/>
            <div className={gridClasses + " gap-3 items-center w-full"}>
                {/* TODO put skeletons instead of loading  */}
                {categoryQuery.isLoading && <Loading/>}
                {categoryQuery.isSuccess && (
                    <Select
                        name="category"
                        labelTopLeft={t("form_category_select_label")}
                        options={categoryOptions}
                        placeholder={selectOneOptionLabel}
                        labelBottomRight={requiredLabel}
                        required={true}
                    />
                )}
                <Input
                    labelTopLeft={t("form_team_input_label")}
                    inputType="text"
                    placeholder={t("form_team_input_placeholder")}
                    name="teamName"
                />
                <Input
                    labelTopLeft={t("form_firstname_input_label")}
                    labelBottomRight={requiredLabel}
                    inputType="text"
                    required={true}
                    name="firstname"
                />
                <Input
                    labelTopLeft={t("form_lastname_input_label")}
                    labelBottomRight={requiredLabel}
                    inputType="text"
                    required={true}
                    name="lastname"
                />
                <Select
                    name="sex"
                    labelTopLeft={t("form_sex_select_label")}
                    options={sexOptions}
                    placeholder={selectOneOptionLabel}
                    labelBottomRight={requiredLabel}
                    required={false}
                />
                <Input
                    labelTopLeft={t("form_birthdate_select_label")}
                    labelBottomRight={requiredLabel}
                    inputType="date"
                    required={true}
                    name="birthdate"
                />
                <Select
                    name="nationality"
                    labelTopLeft={t("form_nationality_select_label")}
                    options={nationalityOptions}
                    placeholder={selectOneOptionLabel}
                    labelBottomRight={requiredLabel}
                    required={true}
                />
                <Input
                    labelTopLeft={t("form_email_input_label")}
                    labelBottomRight={requiredLabel}
                    inputType="email"
                    required={true}
                    name="email"
                />
                <Input
                    labelTopLeft={t("form_phone_racer_input_label")}
                    labelBottomRight={requiredLabel}
                    inputType="tel"
                    required={true}
                    title={t("form_phone_input_title")}
                    pattern="\(\d{3}\)\d{3}-\d{4}|\+\d{1,3}-\d{3}-\d{3}-\d{4}|\+\d{2}\d{4}\d{6}|\d{3}-\d{3}-\d{4}|\d{3}\.\d{3}\.\d{4}"
                    placeholder="+420123123123"
                    name="phoneRacer"
                />
                <Input
                    labelTopLeft={t("form_phone_contact_input_label")}
                    labelBottomRight={requiredLabel}
                    inputType="tel"
                    required={true}
                    title={t("form_phone_input_title")}
                    pattern="\(\d{3}\)\d{3}-\d{4}|\+\d{1,3}-\d{3}-\d{3}-\d{4}|\+\d{2}\d{4}\d{6}|\d{3}-\d{3}-\d{4}|\d{3}\.\d{3}\.\d{4}"
                    placeholder="+420123123123"
                    name="phoneContactPerson"
                />
                <TextArea
                    name="result"
                    labelTopLeft={t("form_achievement_input_label")}
                    required={false}
                    placeholder={t("form_achievement_input_placeholder")}
                />
                <Input
                    labelTopLeft={t("form_voucher_input_label")}
                    inputType="tel"
                    name="voucher"
                />
                <div className="col-span-full">
                    <div className={gridClasses}>
                        <div className="col-span-1">
                            {priceQuery.isLoading && <Loading/>}
                            {priceQuery.isSuccess && <CurrencySelector prices={prices}/>}
                        </div>
                    </div>
                </div>
                <div className="col-span-full">
                    <div className={gridClasses}>
                        <div className="col-span-1">
                            <Checkbox
                                required={true}
                                name="gdpr"
                                title={t("form_gdpr_checkbox_title")}
                                text={t("form_gdpr_checkbox_desc")}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-span-full text-right">
                    {/* TODO maybe move to a separate component */}
                    <button className="btn btn-primary w-full">
                        {navigation.state === "submitting" && (
                            <span className="loading loading-bars"></span>
                        )}
                        {navigation.state === "submitting" &&
                            t("form_button_submitting_text")}
                        {navigation.state !== "submitting" && t("form_button_submit_text")}
                    </button>
                </div>
                {/* TODO maybe once make it nicer */}
                {apiResponseData?.errors && (
                    <div className="col-span-full flex flex-col gap-5">
                        {apiResponseData.errors.map((err: ApiError) => (
                            <ErrorMessage message={err.errorMessage}></ErrorMessage>
                        ))}
                    </div>
                )}
            </div>
        </Form>
    );
};

export default FormElite;

export const registrationFormFenixAction: ActionFunction = async ({
                                                                      request,
                                                                  }) => {
    const formData = await request.formData();

    const data: RegistrationDto = {
        race: {
            raceId: parseInt(formData.get("raceId") as string, 10),
            raceFormat: parseInt(formData.get("raceFormat") as string, 10),
            name: "",
            eventDate: new Date(),
        },
        categories: [
            {
                categoryId: parseInt(formData.get("category") as string, 10),
            },
        ],
        registrationStates: [{value: 0 /**new reg, TODO map states somewhere**/}],
        members: [
            {
                firstname: formData.get("firstname") as string,
                lastname: formData.get("lastname") as string,
                sex: parseInt(formData.get("sex") as string, 10),
                birthdate: formData.get("birthdate") as string,
                nationality: formData.get("nationality") as string,
                email: formData.get("email") as string,
                phone: formData.get("phoneRacer") as string,
                phoneContactPerson: formData.get("phoneContactPerson") as string,
                achievement: formData.get("result") as string,
                registrant: true,
            },
        ],
        voucher: (formData.get("voucher") as string) || undefined,
        gdpr: formData.get("gdpr") === "on",
        team: (formData.get("teamName") as string) || undefined,
        language: i18next.language,
        registrationId: 0,
        //Price will be loaded during process registration
        price: {
            priceId: parseInt(formData.get("priceRadio") as string, 10),
        },
    };

    // const data: RegistrationDto = {
    //   raceId: parseInt(formData.get("raceId") as string, 10),
    //   category: parseInt(formData.get("category") as string, 10),
    //   teamName: (formData.get("teamName") as string) || undefined,
    //   firstname: formData.get("firstname") as string,
    //   lastname: formData.get("lastname") as string,
    //   sex: parseInt(formData.get("sex") as string, 10),
    //   birthdate: formData.get("birthdate") as string,
    //   nationality: formData.get("nationality") as string,
    //   email: formData.get("email") as string,
    //   phoneRacer: formData.get("phoneRacer") as string,
    //   phoneContactPerson: formData.get("phoneContactPerson") as string,
    //   result: formData.get("result") as string,
    //   voucher: (formData.get("voucher") as string) || undefined,
    //   gdpr: formData.get("gdpr") === "on", // Handle checkbox value
    // };

    return handleRegistrationSubmission(data, saveRegistration);
    // try {
    //   const responseData = await saveRegistration(data);

    //   if (responseData.success) {
    //     return { success: true, message: responseData.message };
    //   } else {
    //     //returned error code in response data from spring api
    //     return {
    //       success: false,
    //       errors: [{ errorMessage: responseData.message }],
    //     };
    //   }
    // } catch (error: unknown) {
    //   console.error("Error submitting form:", error);

    //   if (error instanceof AxiosError) {
    //     //validation errors occured
    //     if (error.response && error.response.status === 400) {
    //       // Parse validation errors
    //       const validationErrors = error.response.data;
    //       //TODO resolve any + process the return { success: false, errors: formattedErrors };.... since I expect only this now { success: false, error: error.message };
    //       //Simulate it with empty category, I temporarily left the form to allow it
    //       const formattedErrors: ApiError[] = validationErrors.map(
    //         (err: SpringValidationError) => ({
    //           errorTarget: err.field,
    //           errorMessage: err.defaultMessage,
    //         })
    //       );
    //       return { success: false, errors: formattedErrors };
    //     } else {
    //       //Other than validation related Axios error
    //       return { success: false, errors: [{ errorMessage: error.message }] };
    //     }
    //   } else if (error instanceof Error) {
    //     // Check if it's a generic JavaScript Error
    //     return { success: false, errors: [{ errorMessage: error.message }] };
    //   } else {
    //     // Fallback for unexpected types of errors
    //     return {
    //       success: false,
    //       errors: [{ errorMessage: "An unknown error occurred" }],
    //     };
    //   }
    // }
};
