import defaultImg from "../assets/OCR_Race_Image_1.png";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getRaces } from "../api/raceService";
import { ApiResponse, RaceDto } from "../interface/interface";
import Loading from "./daisyui/Loading";
import useImagePreloader from "../hooks/useImagePreloader";

const Races = () => {
  const images = useImagePreloader();

  const {
    data: apiResponse,
    error,
    isLoading,
  } = useQuery<ApiResponse<RaceDto>, Error>({
    queryKey: ["getRaces"],
    queryFn: getRaces,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    throw error;
    //return <ErrorModal message={error.message} />;
  }

  return (
    <section className="flex flex-col items-center bg-base-200 rounded-lg">
      <ul className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
        {apiResponse &&
          apiResponse.data &&
          apiResponse.data.map((race: RaceDto) => (
            <li key={race.name} className="w-full justify-center">
              <Link to={race.name} className="w-full h-full justify-center">
                <button
                  className="flex flex-col gap-1 w-full h-full justify-center items-center rounded-lg bg-gray-100
            custom-shadow transition-all duration-300 transform hover:scale-105 shadow-lg p-1"
                >
                  <p className="p-2 w-full">
                    <img
                      className="w-full h-auto rounded-lg"
                      src={
                        race.image && images[`${race.image}`]
                          ? images[`${race.image}`]
                          : defaultImg
                      }
                      //src={defaultImg}
                      alt={race.name}
                    />
                  </p>

                  <h1 className="font-semibold">{race.name}</h1>
                  <h1 className="">
                    {new Date(race.eventDate).toLocaleDateString()}
                  </h1>
                </button>
              </Link>
            </li>
          ))}
        {!apiResponse || (!apiResponse.data && <div>No Races found!</div>)}
      </ul>
    </section>
  );
};

export default Races;
