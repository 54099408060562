import { useState, useEffect } from "react";

const useImagePreloader = () => {
  const [images, setImages] = useState<Record<string, string>>({});

  useEffect(() => {
    const loadImages = async () => {
      const modules = import.meta.glob("/src/assets/*.{png,jpg,jpeg,svg}");
      const imagePromises = Object.entries(modules).map(
        async ([path, module]) => {
          const image = (await module()) as { default: string };
          return {
            [path
              .split("/")
              .pop()
              ?.replace(/\.(png|jpe?g|svg)$/, "") ?? ""]: image.default,
          };
        }
      );
      const imageObjects = await Promise.all(imagePromises);
      setImages(Object.assign({}, ...imageObjects));
    };

    loadImages();
  }, []);

  return images;
};

export default useImagePreloader;
