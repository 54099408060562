import { RegistrationDto } from "../interface/interface";
import axiosInstance from "./axiosInstance";

// Add more API methods as needed
// export const getAllRegistrations = async () => {
//   const response = await axiosInstance.get(`/registrations`);
//   return response.data;
// };

// export const getRegistration = async (id: number) => {
//   const response = await axiosInstance.get(`/registrations/${id}`);
//   return response.data;
// };

export const saveRegistration = async (registrationData: RegistrationDto) => {
  try {
    const response = await axiosInstance.post(
      `/api/registration`,
      registrationData
    );
    return response.data;
  } catch (error) {
    console.error("Error new registration:", error);
    throw error;
  }
};
