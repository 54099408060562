import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NavBar: React.FC = () => {
  const { i18n } = useTranslation();

  function changeLanguage(language: string) {
    i18n.changeLanguage(language);
    console.log("language set: " + i18n.language);
  }

  return (
    <div className="mb-5 navbar bg-primary text-primary-content rounded-lg">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow"
          >
            <li>
              <Link to={"/race"} className="font-semibold">
                {t("navbar_all_races")}
              </Link>
            </li>
            {/* <li>
            <a>Organizátoři</a>
            <ul className="p-2">
              <li>
                <a>Submenu 1</a>
              </li>
              <li>
                <a>Submenu 2</a>
              </li>
            </ul>
          </li>
          <li>
            <a>Výsledky</a>
          </li> */}
          </ul>
        </div>
        <p className="text-xl font-semibold hidden sm:block">
          {t("navbar_system_name")}
        </p>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
          <li>
            <Link className="font-semibold" to={"/race"}>
              {t("navbar_all_races")}
            </Link>
          </li>
          {/* <li>
          <details>
            <summary>Organizátoři</summary>
            <ul className="p-2">
              <li>
                <a>Fénix Plzeň</a>
              </li>
              <li>
                <a>Myslív Race</a>
              </li>
            </ul>
          </details>
        </li>
        <li>
          <a>Výsledky</a>
        </li> */}
        </ul>
      </div>
      <div className="navbar-end">
        <div className="flex gap-1">
          <a className="btn btn-link p-1" onClick={() => changeLanguage("cs")}>
            <span className="fi fi-cz"></span>
          </a>
          <a className="btn btn-link p-1" onClick={() => changeLanguage("en")}>
            <span className="fi fi-gb"></span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
