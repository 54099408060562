import FormFenix from "./forms/FormFenix";

//TODO read the race and the registration type (single, dvojice, ...), out of it, choose the type of form

const Registration: React.FC = () => {
  //const { raceId, type } = useParams<{ raceId: string; type: string }>();
  //console.log("This is race id: " + raceId);
  //console.log("This is registration type: " + type);
  //I can choose the form based on the registration type and race id.

  return (
    <>
      {/* <div className="flex flex-row justify-center">
        <h1 className="text-2xl font-bold">
          {t("registration_page_new_title")}
        </h1>
      </div> */}
      <FormFenix />
    </>
  );
};

export default Registration;
