import { Outlet } from "react-router-dom";
import Race from "../components/Race";

const RacePage: React.FC = () => (
  //TODO move to separate component, this is a page
  //   <div className="flex flex-col justify-center items-center">
  //     <img className="w-full h-auto rounded-lg mb-4" src={race1} alt="image" />
  //     <p>Čas konání: 28.2.2024</p>
  //     <p>Místo konání: Škodaland Plzeň</p>
  //     <p>Délka: 3K</p>
  //     <p className="flex flex-row gap-1">
  //       <button className="btn btn-secondary">Registrace</button>
  //     </p>
  //   </div>
  <>
    <Race />
    <Outlet />
  </>
);

export default RacePage;
