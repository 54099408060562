interface InputProps {
  inputType: string;
  labelTopRight?: string;
  labelBottomRight?: string;
  labelTopLeft?: string;
  labelBottomLeft?: string;
  colSpan?: number;
  rowSpan?: number;
  placeholder?: string;
  required?: boolean;
  pattern?: string;
  dataMask?: string;
  name: string;
  title?: string;
}

const Input: React.FC<InputProps> = ({
  inputType = "text",
  labelTopRight = "",
  labelBottomRight = "",
  labelTopLeft = "",
  labelBottomLeft = "",
  placeholder = "",
  colSpan = 1,
  rowSpan = 1,
  required = false,
  pattern = ".*",
  dataMask = "",
  title = "",
  name,
}) => {
  return (
    <label className={`form-control w-full ${colSpan} ${rowSpan}`}>
      <div className="label">
        <span className="label-text">{labelTopLeft}</span>
        <span className="label-text-alt">{labelTopRight}</span>
      </div>
      <input
        title={title}
        name={name}
        type={inputType}
        placeholder={placeholder}
        className="input input-bordered w-full input-primary"
        required={required}
        pattern={pattern}
        data-mask={dataMask}
      />
      <div className="label h-8">
        <span className="label-text-alt">{labelBottomLeft}</span>
        <span className="label-text-alt">{labelBottomRight}</span>
      </div>
    </label>
  );
};

export default Input;
